import deployApi from '@/api/deploy.js';

const state = {
  /** Список проектов */
  projects: {
    isLoading: false,
    data: null,
    errors: null,
  },

  /** Информация по проекту */
  projectInfo: {
    isLoading: false,
    data: null,
    errors: null,
  },

  /** Информация по релизу */
  releaseInfo: {
    isLoading: false,
    data: null,
    errors: null,
  },

  /** Информация о публикации релиза */
  releasePublicationInfo: {
    isLoading: false,
    data: null,
    errors: null,
  },

  /** Содержимого файла лога */
  log: {
    isLoading: false,
    data: null,
    errors: null,
  },
};

const mutations = {
  /**
   * Установка статуса загрузки данных
   *
   * @param   {Array}    state
   * @param   {string}   key      Имя объекта для записи данных
   * @param   {boolean}  status   Статус загрузки данных
   */
  SET_LOADING: (state, { key, status }) => {
    state[key].isLoading = status;
  },

  /**
   * Запись данных
   *
   * @param   {Array}             state
   * @param   {string}            key    Имя объекта для записи данных
   * @param   {Array | Object}    data   Статус загрузки данных
   */
  SET_DATA: (state, { key, data }) => {
    state[key].data = data;
  },

  /**
   * Запись ошибки
   *
   * @param   {Array}     state
   * @param   {string}    key    Имя объекта для записи данных
   * @param   {string}    error  Статус загрузки данных
   */
  SET_ERROR: (state, { key, error }) => {
    state[key].error = error;
  },
};

const actions = {
  /**
   * Получение списка проектов
   *
   * @param   {Function}  commit
   *
   * @return  {Array}             Список проектов
   */
  getProjects({ commit }) {
    commit('SET_LOADING', { key: 'projects', status: true });
    return deployApi
      .getProjects()
      .then(({ data }) => {
        commit('SET_DATA', { key: 'projects', data });
        return data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        commit('SET_LOADING', { key: 'projects', status: false });
      });
  },

  /**
   * Получение информации по проекту
   *
   * @param   {Function}  commit
   * @param   {Object}    params
   * @param   {number}    params.projectId  id проекта
   *
   * @return  {Object}                      Информация по проекту
   */
  getProjectInfo({ commit }, params) {
    commit('SET_LOADING', { key: 'projectInfo', status: true });
    return deployApi
      .getProjectInfo(params)
      .then(({ data }) => {
        commit('SET_DATA', { key: 'projectInfo', data });
        return data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        commit('SET_LOADING', { key: 'projectInfo', status: false });
      });
  },

  /**
   * Получение информации по релизу
   *
   * @param   {Function}  commit
   * @param   {Object}    params
   * @param   {number}    params.releaseId  id релиза
   *
   * @return  {Object}                      Информация по релизу
   */
  getReleaseInfo({ commit }, params) {
    commit('SET_LOADING', { key: 'releaseInfo', status: true });
    return deployApi
      .getReleaseInfo(params)
      .then(({ data }) => {
        commit('SET_DATA', { key: 'releaseInfo', data });
        return data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        commit('SET_LOADING', { key: 'releaseInfo', status: false });
      });
  },

  /**
   * Получение информации о публикации релиза
   *
   * @param   {Function}  commit
   * @param   {Object}    params
   * @param   {number}    params.publicationId  id публикации
   * @param   {number}    params.releaseId      id релиза
   *
   * @return  {Object}                          Информация о публикации релиза
   */
  getReleasePublicationInfo({ commit }, params) {
    commit('SET_LOADING', { key: 'releasePublicationInfo', status: true });
    return deployApi
      .getReleasePublicationInfo(params)
      .then(({ data }) => {
        commit('SET_DATA', {
          key: 'releasePublicationInfo',
          data,
        });
        return data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        commit('SET_LOADING', { key: 'releasePublicationInfo', status: false });
      });
  },

  /**
   * Получение содержимого файла лога
   *
   * @param   {Function}  commit
   * @param   {Object}    params
   * @param   {number}    params.logId  id файла лога
   *
   * @return  {Object}                  Содержимого файла лога
   */
  getLog({ commit }, params) {
    commit('SET_LOADING', { key: 'log', status: true });
    return deployApi
      .getLog(params)
      .then(({ data }) => {
        commit('SET_DATA', { key: 'log', data });
        return data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        commit('SET_LOADING', { key: 'log', status: false });
      });
  },

  /**
   * Добавление нового файла релиза
   *
   * @param   {Object}  context
   * @param   {Array}  payload
   * @param   {number}  payload.projectId      id проекта
   * @param   {Object}  payload.file           файл релиза (zip архив)
   *
   * @return  {Object}                         Информация о добавлении релиза
   */
  addRelease(context, payload) {
    return deployApi
      .addRelease(payload)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        throw error;
      });
  },
};

export default {
  name: 'deploy',
  namespaced: true,
  state,
  mutations,
  actions,
};
