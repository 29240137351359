import { aeroApiMng } from '@/plugins/axios.js';

const getCommonList = ({ name, params }) => {
  return aeroApiMng.get(`Common/${name}`, { params }).then((response) => {
    return response.data.map((option) => ({
      ...option,
      value: option.id,
    }));
  });
};
export default { getCommonList };
