import { aeroApiMng } from '@/plugins/axios.js';

const validateHelpTree = (params) => {
  return aeroApiMng.get('/TechnicalSupportHelpTree/ValidateHelpTree', {
    params,
  });
};

export default {
  validateHelpTree,
};
