import { aeroApiMng } from '@/plugins/axios.js';

const getPeriodList = () => {
  return aeroApiMng.get('/OneCContracts/GetPeriodList');
};

const getProducts = (params) => {
  return aeroApiMng.get('/OneCContracts/GetProducts', { params });
};

const getContractTypes = (params) => {
  return aeroApiMng.get('OneCContracts/GetContractTypes', { params });
};

const createContract = (payload) => {
  return aeroApiMng.post('/OneCContracts/CreateContract', payload);
};

export default {
  getPeriodList,
  getProducts,
  getContractTypes,
  createContract,
};
