import { aeroApiMng } from '@/plugins/axios.js';

const showLog = (payload) => {
  return aeroApiMng.post('/RequestQueryReport/ShowLog', payload);
};

const restartTasks = (payload) => {
  return aeroApiMng.post('/RequestQueryReport/RestartTasks', payload);
};

const downloadFile = (params) => {
  return aeroApiMng.get('/RequestQueryReport/DownloadFile', {
    responseType: 'blob',
    params,
  });
};

export default {
  showLog,
  restartTasks,
  downloadFile,
};
