import remindersApi from '@/api/reminders.js';

const state = {
  reminders: [],
};

const mutations = {
  SET_REMINDERS: (state, reminders) => {
    state.reminders = reminders;
  },
};

const actions = {
  createManyReminders(context, payload) {
    return remindersApi
      .createManyReminders(payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  closeReminder(context, payload) {
    return remindersApi
      .closeReminder(payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  getActualReminders({ commit }) {
    return remindersApi
      .getActualReminders()
      .then(({ data }) => {
        commit('SET_REMINDERS', data);
        return data;
      })
      .catch((error) => {
        throw error;
      });
  },
};

export default {
  name: 'reminders',
  namespaced: true,
  state,
  mutations,
  actions,
};
