import { cloneDeep as _cloneDeep } from 'lodash';

import siteSettingsApi from '@/api/siteSettings.js';

const MODULE_STATE = {
  isLoading: false,
  data: null,
  errors: null,
};

const state = {
  settings: _cloneDeep(MODULE_STATE),
};

const mutations = {
  SET_LOADING: (state, { key, status }) => {
    state[key].isLoading = status;
  },

  SET_DATA: (state, { key, data }) => {
    state[key].data = data;
  },

  SET_ERROR: (state, { key, error }) => {
    state[key].error = error;
  },
};

const actions = {
  getSiteSettings({ commit }, dbName) {
    commit('SET_LOADING', { key: 'settings', status: true });
    return siteSettingsApi
      .getSettings(dbName)
      .then((data) => {
        commit('SET_DATA', { key: 'settings', data });
      })
      .catch(() => {
        commit('SET_ERROR', { key: 'settings', error: true });
      })
      .finally(() => {
        commit('SET_LOADING', { key: 'settings', status: false });
      });
  },

  setSiteSettings(ctx, { dbName, settings }) {
    return siteSettingsApi.setSettings({ dbName, settings });
  },
};

export default {
  name: 'siteSettings',
  namespaced: true,
  state,
  mutations,
  actions,
};
