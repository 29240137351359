<template>
  <button
    :disabled="disabled || isLoading"
    class="button"
    :class="classes"
    @click="$emit('click')"
  >
    <sm-spinner v-if="isLoading" class="button__loader" />
    <slot v-else></slot>
  </button>
</template>
<script>
import SmSpinner from '@/components/common/SmSpinner.vue';
export default {
  name: 'SmButton',

  components: {
    SmSpinner,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    outline: {
      type: Boolean,
      default: false,
    },

    neutrall: {
      type: Boolean,
      default: false,
    },

    secondary: {
      type: Boolean,
      default: false,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes() {
      return {
        'button--outline': this.outline,
        'button--secondary': this.secondary,
        'button--neutrall': this.neutrall,
      };
    },
  },
};
</script>

<style lang="scss">
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 36px;
  padding: 0 14px;

  font-size: 14px;
  font-weight: 500;
  color: var(--white);

  border: none;
  border-radius: 5px;
  background-color: var(--blue);

  box-shadow: 7px 7px 20px rgb(var(--rgb-blue) / 9%);

  transition: background 0.3s;

  &:hover {
    background-color: var(--dark-blue);
  }

  &:disabled {
    background-color: var(--gray);
  }
}

.button--outline {
  border: 0.5px solid rgba(0, 160, 227, 0.4);

  background-color: transparent;
}

.button--outline:hover {
  background-color: transparent;
}

.button--neutrall {
  background-color: var(--gray);
}

.button--neutrall.button--outline {
  color: var(--gray);

  border: 1px solid var(--gray);

  background-color: var(--white);
  box-shadow: 7px 7px 20px rgba(152, 152, 152, 0.1);
}

.button__loader.spinner {
  width: 20px;
  height: 20px;
}

.button__loader.spinner div {
  transform-origin: 10px 10px;
}

.button__loader.spinner div::after {
  top: 8px;
  left: 0px;
  width: 2px;
  height: 2px;

  border-radius: 50%;

  background-color: var(--white);
}
</style>
