import { DEVELOPMENT } from '../constants/env.js'

/**
 * Режим разработки
 * @returns Boolean
 */
const IsDevelopment = process.env.NODE_ENV === DEVELOPMENT

export default {
  IsDevelopment
}