import { cloneDeep as _cloneDeep } from 'lodash';

import downloadHelpers from '@/utils/downloadHelpers.js';

import customersApi from '@/api/customers.js';

const MODULE_STATE = {
  isLoading: false,
  data: null,
  error: false,
};

const state = {
  contracts: _cloneDeep(MODULE_STATE),
  clientInfo: _cloneDeep(MODULE_STATE)
};

const mutations = {
  SET_LOADING: (state, { key, status }) => {
    state[key].isLoading = status;
  },

  SET_DATA: (state, { key, data }) => {
    state[key].data = data;
  },

  SET_ERROR: (state, { key, error }) => {
    state[key].error = error;
  },

  CLEAR_MODULE_STATE: (state, { key }) => {
    state[key] = _cloneDeep(MODULE_STATE);
  },
};

const actions = {
  clearModuleState({ commit }, key) {
    commit('CLEAR_MODULE_STATE', { key });
  },

  getContracts({ commit }, customersId) {
    commit('SET_LOADING', { key: 'contracts', status: true });
    customersApi
      .getContracts(customersId)
      .then((data) => {
        commit('SET_DATA', { key: 'contracts', data: data.contracts });
      })
      .catch(() => {
        commit('SET_ERROR', { key: 'contracts', status: true });
      })
      .finally(() => {
        commit('SET_LOADING', { key: 'contracts', status: false });
      });
  },

  downloadContract(ctx, { customerId, contractNumber }) {
    customersApi
      .downloadContract({ customerId, contractNumber })
      .then((response) => {
        downloadHelpers(response.data, response.headers['content-disposition']);
      });
  },

  uploadFile(ctx, { fileGuid, fileName, customerId, contractNumber }) {
    customersApi.uploadFile({ fileGuid, fileName, customerId, contractNumber });
  },

  fetchKpp({ commit }, inn) {
    commit('SET_LOADING', { key: 'clientInfo', status: true });
    commit('SET_ERROR', { key: 'clientInfo', error: null });

    return customersApi
      .getKpp(inn)
      .then(({ data }) => {
        if (data) { 
          return data
        }

        commit('SET_ERROR', { key: 'clientInfo', error: true });
        return;
      })
      .catch(() => {
        commit('SET_ERROR', { key: 'clientInfo', error: true });
        return true;
      })
      .finally(() => {
        commit('SET_LOADING', { key: 'clientInfo', status: false });
      });
  },

  stopCooperation(ctx, id) {
    return customersApi
      .stopCooperation(id)
      .then((response) => {
        return response;
      });
  },
};

export default {
  name: 'customers',
  namespaced: true,
  state,
  mutations,
  actions,
};
