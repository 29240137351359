const AERO_API_MNG = process.env.VUE_APP_AERO_API_MNG;
const API_MNG = process.env.VUE_APP_API_MNG;
const API_EXCH = process.env.VUE_APP_API_EXCH;
const API_ZADARMA = process.env.VUE_APP_ZADARMA_API;

export {
  AERO_API_MNG,
  API_MNG,
  API_EXCH,
  API_ZADARMA
}
