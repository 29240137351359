import accountApi from '@/api/account.js';
import ability from '@/plugins/ability.js';

const state = {
  user: {
    isLoading: false,
    data: [],
    error: null,
  },
};

const mutations = {
  SET_LOADING: (state, { key, status }) => {
    state[key].isLoading = status;
  },

  SET_DATA: (state, { key, data }) => {
    state[key].data = data.fio;
    localStorage.setItem('token', data.acx);
    localStorage.setItem('accountId', data.accountId);
  },

  SET_ERROR: (state, { key, error }) => {
    state[key].error = error;
  },

  CLEAR_DATA: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('accountId');
    localStorage.removeItem('hidePersonalData');
  },

  SET_PERMISSIONS: (context, data) => {
    const { hasFullAccess, permissions } = data;
    const permissionsList =
      permissions && permissions.length ? permissions : [];
    ability.setPermissions({ hasFullAccess, list: permissionsList });
  },
};

const actions = {
  login({ commit }, payload) {
    commit('SET_LOADING', { key: 'user', status: true });
    return (
      accountApi
        .login(payload)
        .then((response) => {
          const { data } = response;
          if (response.data.isSucceed) {
            commit('SET_DATA', { key: 'user', data });
          } else {
            commit('SET_ERROR', { key: 'user', error: data.error });
          }
          return data;
        })
        // .catch((error) => {
        //   commit('SET_ERROR', { key: 'user', err: error });
        // })
        .finally(() => {
          commit('SET_LOADING', { key: 'user', status: false });
        })
    );
  },

  logout({ commit }) {
    commit('CLEAR_DATA');
  },

  getUserPermissions({ commit }) {
    return accountApi.getUserPermissions().then((response) => {
      commit('SET_PERMISSIONS', response.data);
      return response.data;
    });
  },
};

export default {
  name: 'account',
  namespaced: true,
  state,
  mutations,
  actions,
};
