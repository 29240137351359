import { aeroApiMng } from '@/plugins/axios.js';

const addFile = (payload) => {
  return aeroApiMng.post('/PendingFiles/Add', payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const removeFiles = (payload) => {
  return aeroApiMng.post('/PendingFiles/Remove', payload);
};

export default {
  addFile,
  removeFiles,
};
