import { aeroApiMng } from '@/plugins/axios.js';

const setAsReaded = (id) => {
  return aeroApiMng.get('/MailBoxesMessages/SetAsReaded', { params: { id } });
};
const getMailBoxesUpdates = () => {
  return aeroApiMng.get('/MailBoxesMessages/GetMailBoxesUpdates');  
};

export default {
  setAsReaded,
  getMailBoxesUpdates,
};
