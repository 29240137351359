import oneCContractsApi from '@/api/oneCContracts.js';

const state = {
  periodList: {
    isLoading: false,
    data: [],
    errors: null,
  },

  products: {
    isLoading: false,
    data: [],
    errors: null,
  },

  contractTypes: {
    isLoading: false,
    data: [],
    errors: null,
  },

  contractInfo: {
    isLoading: false,
    data: '',
    errors: null,
  },
};

const mutations = {
  SET_LOADING: (state, { key, status }) => {
    state[key].isLoading = status;
  },

  SET_DATA: (state, { key, data }) => {
    state[key].data = data;
  },

  SET_ERROR: (state, { key, error }) => {
    state[key].error = error;
  },
};

const actions = {
  getPeriodList({ commit }) {
    const key = 'periodList';

    commit('SET_LOADING', { key, status: true });
    return oneCContractsApi
      .getPeriodList()
      .then(({ data }) => {
        commit('SET_DATA', { key, data });
        return data;
      })
      .catch((error) => {
        commit('SET_ERROR', { key, error: error });
      })
      .finally(() => {
        commit('SET_LOADING', { key, status: false });
      });
  },

  getProducts({ commit }, params) {
    const key = 'products';

    commit('SET_LOADING', { key, status: true });
    return oneCContractsApi
      .getProducts(params)
      .then(({ data }) => {
        commit('SET_DATA', { key, data });
        return data;
      })
      .catch((error) => {
        commit('SET_ERROR', { key, error: error });
      })
      .finally(() => {
        commit('SET_LOADING', { key, status: false });
      });
  },

  getContractTypes({ commit }, params) {
    const key = 'contractTypes';

    commit('SET_LOADING', { key, status: true });
    return oneCContractsApi
      .getContractTypes(params)
      .then(({ data }) => {
        commit('SET_DATA', { key, data });
        return data;
      })
      .catch((error) => {
        commit('SET_ERROR', { key, error: error });
      })
      .finally(() => {
        commit('SET_LOADING', { key, status: false });
      });
  },

  createContract({ commit }, payload) {
    const key = 'contractInfo';

    return oneCContractsApi
      .createContract(payload)
      .then(({ data }) => {
        commit('SET_DATA', { key, data });
        return data;
      })
      .catch((error) => {
        commit('SET_ERROR', { key, error: error });
      })
      .finally(() => {
        commit('SET_LOADING', { key, status: false });
      });
  },
};

export default {
  name: 'oneCContracts',
  namespaced: true,
  state,
  mutations,
  actions,
};
