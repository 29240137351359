const unauthorizedRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: {
      title: 'КСП | Авторизация',
    },
  },
];

export default unauthorizedRoutes.map((route) => {
  route.meta = {
    middleware: { unauthorizedOnly: true },
    layout: 'Empty',
    ...route.meta,
  };
  return route;
});
