import technicalSupportHelpTreeApi from '@/api/technicalSupportHelpTree.js';

const actions = {
  validateHelpTree(context, params) {
    return technicalSupportHelpTreeApi
      .validateHelpTree(params)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
};

export default {
  name: 'technicalSupportHelpTree',
  namespaced: true,
  actions,
};
