import { aeroApiMng } from '@/plugins/axios.js';

const getList = ({ name, params }) => {
  return aeroApiMng.post(`/${name}/List`, params);
};

const getChildList = ({ name, parentName, parentId, params }) => {
  return aeroApiMng.post(`/${name}/List/For${parentName}/${parentId}`, params);
};

const getListParameters = ({ name }) => {
  return aeroApiMng.get(`/${name}/ListParameters`);
};

const getItem = ({ name, id }) => {
  return aeroApiMng.get(`/${name}/item`, { params: { id } });
};

const createItem = ({ name, payload }) => {
  return aeroApiMng.post(`/${name}/New`, payload);
};

const updateItem = ({ name, payload }) => {
  return aeroApiMng.post(`/${name}/Update`, payload);
};

const removeItem = ({ name, ids }) => {
  return aeroApiMng.post(`/${name}/Remove`, { ids });
};

const downloadFile = ({ name, id, type }) => {
  return aeroApiMng.get(`/${name}/Download/${type}/${id}`, {
    responseType: 'blob',
  });
};

export default {
  name: 'editableList',
  getList,
  getChildList,
  getListParameters,
  getItem,
  createItem,
  updateItem,
  removeItem,
  downloadFile,
};
