import { aeroApiMng } from '@/plugins/axios.js';

const API_ENDPOINT = 'Customers';

const attachAccount = (payload) => {
  return aeroApiMng.post(`/${API_ENDPOINT}/AttachAccount`, payload);
};

const detachAccount = (payload) => {
  return aeroApiMng.post(`/${API_ENDPOINT}/DetachAccount`, payload);
};

const getContracts = (customerId) => {
  return aeroApiMng
    .get(`${API_ENDPOINT}/GetContracts`, { params: { customerId } })
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return { contracts: [] };
    });
};

const downloadContract = ({ customerId, contractNumber }) => {
  return aeroApiMng.get(`${API_ENDPOINT}/DownloadContract`, {
    params: { customerId, contractNumber },
    responseType: 'blob',
  });
};

const uploadFile = ({ fileGuid, fileName, customerId, contractNumber }) => {
  return aeroApiMng.post(`${API_ENDPOINT}/UploadContract`, {
    fileGuid,
    fileName,
    customerId,
    contractNumber,
  });
};

const getKpp = (inn) => {
  return aeroApiMng.get(`${API_ENDPOINT}/GetKpp`, {
    params: {
      inn
    }
  })
};

const stopCooperation = (id) => {
  return aeroApiMng
    .post(`${API_ENDPOINT}/TerminationService`, { id })
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return {
        "isSucceed": false
      };
    });
};

export default {
  attachAccount,
  detachAccount,
  getContracts,
  downloadContract,
  uploadFile,
  getKpp,
  stopCooperation,
};
