import Vue from 'vue';
import './scss/main.scss';
import processMode from './utils/processModeHelper'

import { store } from './store';
import router from './router';

import App from './App.vue';

import ability from './plugins/ability.js';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';

Vue.config.productionTip = false;

Vue.use(ability, ability);
Vue.use(VueLodash, { lodash });
Vue.use(require('vue-moment'));

Vue.directive('click-outside', clickOutside);
import clickOutside from './directives/clickOutside.js';

if(processMode.IsDevelopment){
  console.warn('Development mode')
}

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount('#app');
