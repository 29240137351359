import visitedPagesApi from '@/api/visitedPages.js';

const state = {
  visitedPages: {
    isLoading: false,
    data: null,
    errors: null,
  },
};

const mutations = {
  SET_LOADING: (state, { key, status }) => {
    state[key].isLoading = status;
  },

  SET_DATA: (state, { key, data }) => {
    state[key].data = data;
  },

  SET_ERROR: (state, { key, error }) => {
    state[key].error = error;
  },
};

const actions = {
  getPages({ commit }, params) {
    commit('SET_LOADING', { key: 'visitedPages', status: true });
    return visitedPagesApi
      .getPages(params)
      .then((response) => {
        commit('SET_DATA', { key: 'visitedPages', data: response.data });
        return response.data;
      })
      .finally(() => {
        commit('SET_LOADING', { key: 'visitedPages', status: false });
      });
  },

  registerVisitedPage(context, payload) {
    return visitedPagesApi.registerVisitedPage(payload).then((response) => {
      return response.data;
    });
  },
};

export default {
  name: 'visitedPages',
  namespaced: true,
  state,
  mutations,
  actions,
};
