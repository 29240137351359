import { aeroApiMng } from '@/plugins/axios.js';

const login = (payload) => {
  return aeroApiMng.post('/auth/login', payload);
};

const getUserPermissions = () => {
  return aeroApiMng.get('/User/Permissions');
};

export default {
  login,
  getUserPermissions,
};
