import filterApi from '/src/api/filter';

const state = {
  domaaidatabases: {
    isLoading: false,
    data: null,
    errors: null,
  },

  telemarketingproducts: {
    isLoading: false,
    data: null,
    errors: null,
  },

  telemarketingscripts: {
    isLoading: false,
    data: null,
    errors: null,
  },

  technicalSupportHelpTreeParents: {
    isLoading: false,
    data: null,
    errors: null,
  },
};

const mutations = {
  SET_LOADING: (state, { key, status }) => {
    state[key].isLoading = status;
  },

  SET_DATA: (state, { key, data }) => {
    state[key].data = data;
  },

  SET_ERROR: (state, { key, error }) => {
    state[key].errors = error;
  },

  SET_MULTIPLE_DATA: (state, { key, data }) => {
    if (state[key].data.items) {
      data.items.forEach((item) => {
        if (!state[key].data.items.some((el) => el.id === item.id)) {
          state[key].data.items.push(item);
        }
      });
      state[key].data.items.sort((a, b) => a.id - b.id);
    } else {
      state[key].data = data;
    }
  },

  RESET_MULTIPLE_DATA: (state, { key }) => {
    state[key].data = [];
  },
};

const actions = {
  getFilterCatalogs({ commit }, requestData) {
    let key = requestData.catalogName;

    commit('SET_LOADING', { key, status: true });
    return filterApi
      .getFilterCatalogs(requestData)
      .then((data) => {
        commit('SET_DATA', { key, data });
        return data;
      })
      .catch((err) => {
        commit('SET_ERROR', { key, err: err });
      })
      .finally(() => {
        commit('SET_LOADING', { key, status: false });
      });
  },

  getFilterItemsById(ctx, requestData) {
    return filterApi
      .getFilterItemsById(requestData.payload)
      .then((response) => {
        return response.data;
      });
  },

  getFilterCatalogsItems({ commit }, requestData) {
    let key = requestData.name;

    commit('SET_LOADING', { key, status: true });
    return filterApi
      .getFilterCatalogsItems(requestData)
      .then((data) => {
        commit('SET_DATA', { key, data });
        return data;
      })
      .catch((err) => {
        commit('SET_ERROR', { key, err: err });
      })
      .finally(() => {
        commit('SET_LOADING', { key, status: false });
      });
  },

  getMultipleFilterCatalogs({ commit }, requestData) {
    let key = requestData.catalogName;
    let parentItems = requestData.parentItems;

    commit('RESET_MULTIPLE_DATA', { key });
    commit('SET_LOADING', { key, status: true });
    return parentItems.forEach((item) => {
      const params = {
        parentId: item,
      };

      filterApi
        .getFilterCatalogs({
          catalogName: key,
          params,
        })
        .then((data) => {
          commit('SET_MULTIPLE_DATA', { key, data });
          return data;
        })
        .catch(() => {
          commit('SET_ERROR', { key, err: item });
        })
        .finally(() => {
          commit('SET_LOADING', { key, status: false });
        });
    });
  },
};

export default {
  name: 'filter',
  namespaced: true,
  state,
  mutations,
  actions,
};
