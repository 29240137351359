import { aeroApiMng } from '@/plugins/axios.js';

const meterRecheckRequests = (payload) => {
  return aeroApiMng.post('/MeterRecheckRequests/Items', payload);
};
const downloadAct = (payload) => {
  return aeroApiMng.get('/MeterRecheckRequests/DownloadAct', {
    params: payload,
    responseType: 'blob',
  });
};

const metersInfo = (payload) => {
  return aeroApiMng.get('/MeterRecheckRequests/MetersInfo', {
    params: payload,
  });
};

const resources = (payload) => {
  return aeroApiMng.get('/MeterRecheckRequests/Resources', {
    params: payload,
  });
};

const updateMetersInfo = (payload) => {
  return aeroApiMng.post('/MeterRecheckRequests/UpdateMetersInfo', payload);
};

export default {
  meterRecheckRequests,
  downloadAct,
  metersInfo,
  resources,
  updateMetersInfo,
};