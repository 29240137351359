import amoCrmApi from '@/api/amoCrm.js';

const state = {};

const mutations = {};

const actions = {
  addCompanyToAmo(context, payload) {
    return amoCrmApi
      .addCompanyToAmo(payload)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response.status === 400) {
          throw error.response.data.message;
        }
      });
  },
};

export default {
  name: 'amoCrm',
  namespaced: true,
  state,
  mutations,
  actions,
};
