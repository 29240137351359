export default (blob, contentDisposition) => {
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  const utf8RegExp = /=\?utf-8\?B\?/;
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(contentDisposition);
  let filename = '';

  filename = matches ? matches[1].replace(/(['"])/g, '') : 'file';

  if (utf8RegExp.test(filename)) {
    filename = decodeURIComponent(
      escape(window.atob(filename.replace(utf8RegExp, '').replace(/\?=/, '')))
    );
  }

  link.href = blobUrl;
  link.download = filename;

  document.body.appendChild(link);

  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  document.body.removeChild(link);
};
