import dbMaintenanceApi from '@/api/dbMaintenance.js';

const state = {
  dbMaintenance: {
    isLoading: false,
    data: null,
    error: null,
  },
  demoDbMaintenance: {
    isLoading: false,
    data: null,
    error: null,
  },
};

const mutations = {
  SET_LOADING: (state, { key, status }) => {
    state[key].isLoading = status;
  },

  SET_DATA: (state, { key, data }) => {
    state[key].data = data;
  },

  SET_ERROR: (state, { key, error }) => {
    state[key].error = error;
  },
};

const actions = {
  createDatabase({ commit }, payload) {
    commit('SET_LOADING', { key: 'dbMaintenance', status: true });
    return dbMaintenanceApi
      .createDatabase(payload)
      .then(({ data }) => {
        if (data.isSucceed) {
          commit('SET_DATA', { key: 'dbMaintenance', data });
        } else {
          commit('SET_ERROR', { key: 'dbMaintenance', error: data.error });
        }
        return data;
      })
      .finally(() => {
        commit('SET_LOADING', { key: 'dbMaintenance', status: false });
      });
  },
  createDemoDatabase({ commit }, payload) {
    commit('SET_LOADING', { key: 'demoDbMaintenance', status: true });

    return dbMaintenanceApi
      .createDemoDatabase(payload)
      .then(({ data }) => {
        if (data.isSucceed) {
          commit('SET_DATA', { key: 'demoDbMaintenance', data });
        } else {
          commit('SET_ERROR', { key: 'demoDbMaintenance', error: data.error });
        }

        return data;
      })
      .finally(() => {
        commit('SET_LOADING', { key: 'demoDbMaintenance', status: false });
      });
  },
  truncateDatabase({ commit }, payload) {
    commit('SET_LOADING', { key: 'dbMaintenance', status: true });
    return dbMaintenanceApi
      .truncateDatabase(payload)
      .then((response) => {
        commit('SET_DATA', { key: 'dbMaintenance', data: response.data });
        return response.data;
      })
      .finally(() => {
        commit('SET_LOADING', { key: 'dbMaintenance', status: false });
      });
  },
};

export default {
  name: 'dbMaintenance',
  namespaced: true,
  state,
  mutations,
  actions,
};
