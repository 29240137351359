import requestQueryReportApi from '@/api/requestQueryReport.js';
import downloadHelpers from '@/utils/downloadHelpers.js';

const actions = {
  showLog(context, payload) {
    return requestQueryReportApi
      .showLog(payload)
      .then((response) => response.data);
  },

  restartTasks(context, payload) {
    return requestQueryReportApi.restartTasks(payload).then((response) => {
      return response.data;
    });
  },

  downloadFile(context, params) {
    return requestQueryReportApi.downloadFile(params).then((response) => {
      downloadHelpers(response.data, response.headers['content-disposition']);
    });
  },
};

export default {
  name: 'requestQueryReport',
  namespaced: true,
  actions,
};
