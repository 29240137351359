import debtorsApi from '@/api/debtors.js';

const actions = {
  notificationAboutDebt(context, isNeedSendSms) {
    return debtorsApi
      .notificationAboutDebt(isNeedSendSms)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
};

export default {
  name: 'debtors',
  namespaced: true,
  actions,
};