import Vue from 'vue';
import Router from 'vue-router';
import unauthorizedRoutes from './unauthorizedRoutes.js';
import authorizedRoutes from './authorizedRoutes.js';

Vue.use(Router);

const routes = [...unauthorizedRoutes, ...authorizedRoutes];

const router = new Router({
  // mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

const defaultTitle = 'КСП';

router.beforeEach((to, _, next) => {
  const token = localStorage.getItem('token');
  const unauthorizedOnly = to.meta.middleware.unauthorizedOnly;

  if (!unauthorizedOnly && !token) {
    return next('/login');
  }

  if (unauthorizedOnly && token) {
    return next('/');
  }

  next();
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || defaultTitle;
  });
});

export default router;
