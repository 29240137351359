import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { AERO_API_MNG, API_MNG, API_EXCH } from '@/constants/url.js';
import { store } from '@/store';

Vue.use(VueAxios, axios);

const clientTimeZone = (new Date().getTimezoneOffset() / 60) * -1;

const createAxios = (baseURL) => {
  const instance = axios.create({ baseURL });

  instance.interceptors.request.use((config) => {
    let token = localStorage.getItem('token');

    config.headers.acx = token ? token : '';
    config.headers.ClientTimeZone = clientTimeZone;
    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      if (
        response?.status === 200 &&
        response?.data.error &&
        !response?.data.isSucceed &&
        !['/Sip/WebRTCKey'].includes(response.config.url)
      ) {
        Vue.prototype.$notify({
          header: 'Ошибка',
          text: response.data.error,
          type: 'error',
          timer: 5000,
        });
      }
      return response;
    },

    (error) => {
      if (error.response?.status === 401) {
        store.dispatch('account/logout');
        if (document.location.hash !== '#/login') {
          location.href = location.href.split('#')[0];
        }
      }

      throw error;
    }
  );

  return instance;
};

const aeroApiMng = createAxios(AERO_API_MNG);
const apiMng = createAxios(API_MNG);
const apiExch = createAxios(API_EXCH);

export { aeroApiMng, apiMng, apiExch };
