import pendingFilesApi from '@/api/pendingFiles.js';

const state = {};

const mutations = {};

const actions = {
  addFile(context, payload) {
    return pendingFilesApi.addFile(payload).then((res) => {
      return res.data;
    });
  },

  removeFiles(context, payload) {
    return pendingFilesApi.removeFiles(payload).then((res) => {
      return res.data;
    });
  },
};

export default {
  name: 'pendingFiles',
  namespaced: true,
  state,
  mutations,
  actions,
};
