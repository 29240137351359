import { aeroApiMng } from '@/plugins/axios.js';

const createManyReminders = (payload) =>
  aeroApiMng.post('/Reminders/NewMany', payload);

const closeReminder = (payload) => aeroApiMng.post('/Reminders/Close', payload);

const getActualReminders = () =>
  aeroApiMng.post('/Reminders/List', {
    count: 150,
    filters: [{ column: 'isActual', value: true, operation: 'Equal' }],
    orderDesc: true,
    skip: 0,
  });

export default {
  createManyReminders,
  closeReminder,
  getActualReminders,
};
