<template>
  <div class="page-loader">
    <sm-spinner />
  </div>
</template>

<script>
import SmSpinner from '@/components/common/SmSpinner.vue';

export default {
  name: 'SmPageLoader',

  components: {
    SmSpinner,
  },
};
</script>

<style lang="scss">
.page-loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 97;

  display: flex;
  justify-content: center;
  align-items: center;

  // background: rgba(var(--rgb-black), 0.8);
  background-color: var(--white);
}
</style>
