import { aeroApiMng } from '@/plugins/axios.js';

const getPages = ({ groupName }) => {
  return aeroApiMng.get(`/VisitedPages/GetPageInfo?group=${groupName}`);
};

const registerVisitedPage = (payload) => {
  return aeroApiMng.post(`/VisitedPages/RegisterVisitedPage`, payload);
};

export default {
  getPages,
  registerVisitedPage,
};
