import editableListApi from '@/api/editableList.js';
import downloadHelpers from '@/utils/downloadHelpers.js';

const state = {
  list: {
    isLoading: false,
    data: null,
    errors: null,
  },

  childList: {
    isLoading: false,
    data: null,
    errors: null,
  },

  item: {
    isLoading: false,
    data: null,
    errors: null,
  },

  listParameters: {
    isLoading: false,
    data: null,
    errors: null,
  },
};

const mutations = {
  SET_LOADING: (state, { key, status }) => {
    state[key].isLoading = status;
  },

  SET_DATA: (state, { key, data }) => {
    state[key].data = data;
  },

  SET_ERROR: (state, { key, error }) => {
    state[key].error = error;
  },

  CLEAR_STATE: (state) => {
    const keys = ['list', 'childList', 'item', 'listParameters'];
    keys.forEach((key) => {
      state[key].data = null;
    });
  },
};

const actions = {
  getList({ commit }, requestData) {
    commit('SET_LOADING', { key: 'list', status: true });
    return editableListApi
      .getList(requestData)
      .then((response) => {
        commit('SET_DATA', { key: 'list', data: response.data });
        return response.data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        commit('SET_LOADING', { key: 'list', status: false });
      });
  },

  getChildList({ commit }, requestData) {
    commit('SET_LOADING', { key: 'childList', status: true });
    return editableListApi
      .getChildList(requestData)
      .then((response) => {
        commit('SET_DATA', { key: 'childList', data: response.data });
        return response.data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        commit('SET_LOADING', { key: 'childList', status: false });
      });
  },

  getListforCommon(context, payload) {
    return editableListApi
      .getList(payload)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        throw error;
      });
  },

  getListParameters({ commit }, requestData) {
    commit('SET_LOADING', { key: 'listParameters', status: true });
    return editableListApi
      .getListParameters(requestData)
      .then((response) => {
        commit('SET_DATA', { key: 'listParameters', data: response.data });
        return response.data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        commit('SET_LOADING', { key: 'listParameters', status: false });
      });
  },

  getItem({ commit }, requestData) {
    commit('SET_LOADING', { key: 'item', status: true });
    return editableListApi
      .getItem(requestData)
      .then((response) => {
        commit('SET_DATA', { key: 'item', data: response.data });
        return response.data;
      })
      .catch((error) => {
        commit('SET_ERROR', { key: 'item', error: error.response.data.errors });
      })
      .finally(() => {
        commit('SET_LOADING', { key: 'item', status: false });
      });
  },

  createItem(context, requestData) {
    return editableListApi
      .createItem(requestData)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  updateItem(context, requestData) {
    return editableListApi
      .updateItem(requestData)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  removeItem(context, requestData) {
    return editableListApi
      .removeItem(requestData)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  downloadFile(context, requestData) {
    editableListApi.downloadFile(requestData).then((response) => {
      downloadHelpers(response.data, response.headers['content-disposition']);
    });
  },

  clearState({ commit }) {
    commit('CLEAR_STATE');
  },
};

export default {
  name: 'editableList',
  namespaced: true,
  state,
  mutations,
  actions,
};
