<template>
  <div id="app">
    <sm-page-loader v-if="isLoadingApp" />
    <vue-extend-layouts v-if="!isLoadingApp" />
    <sm-confirm-modal ref="confirmModal" />
    <sm-notification />
  </div>
</template>

<script>
// vuex
import { mapActions } from 'vuex';
// components
import VueExtendLayouts from 'vue-extend-layout';
import SmPageLoader from '@/components/common/SmPageLoader.vue';
import SmConfirmModal from '@/components/common/modals/SmConfirmModal.vue';
import SmNotification from '@/components/common/notification/SmNotification.vue';
export default {
  name: 'App',

  components: {
    VueExtendLayouts,
    SmPageLoader,
    SmConfirmModal,
    SmNotification,
  },

  data() {
    return {
      isLoadingApp: false,
    };
  },

  created() {
  const token = localStorage.getItem('token');
  if (token && token.length) {
    this.isLoadingApp = true;

    this.getItem({
      name: 'AccountEmployees',
      id: localStorage.getItem('accountId'),
    }).then((state) => {
      localStorage.setItem('hidePersonalData', state?.hidePersonalData);
    });

    this.getUserPermissions().then(() => {
      this.isLoadingApp = false;
    });
  }

  const refreshPermissionsInterval = setInterval(() => {
    if (token && token.length) {
      this.actualizationReminders();
      clearInterval(refreshPermissionsInterval);
    }
  }, 500);

  setInterval(() => {
    if (token && token.length) {
      this.actualizationReminders();
    }
  }, 300000);
},


  mounted() {
    this.$root.$confirmModal = this.$refs.confirmModal.open;
  },

  methods: {
    ...mapActions({
      getUserPermissions: 'account/getUserPermissions',
      getActualReminders: 'reminders/getActualReminders',
      getBalance: 'balance/getBalance',
      getItem: 'editableList/getItem',
    }),

    actualizationReminders() {
      this.getActualReminders().then(({ items }) => {
        items.forEach((item) => {
          const currentDate = new Date();

          const validDate = this.$moment(currentDate, 'DD.MM.YYYY HH:mm')
            .set('minute', currentDate.getMinutes() + 5)
            .toISOString();

          const whenRemind = this.$moment(
            item.whenRemind,
            'DD.MM.YYYY HH:mm'
          ).toISOString();

          const isAfter = this.$moment(whenRemind).isAfter(currentDate);
          const isBefore = this.$moment(whenRemind).isBefore(validDate);

          if (isAfter && isBefore) {
            const messageTime = this.$moment(
              item.whenRemind,
              'DD.MM.YYYY HH:mm:ss'
            ).format('HH:mm');
            this.$notify({
              header: `Напоминание ${messageTime}`,
              text: item.relatedObjectId
                ? `<p>${item.comment}</p><a class="notification__link" href="/#/telemarketing/telemarketing-tasks-for-current-user/edit/${item.relatedObjectId}">Перейти к заданию по телемаркетингу №${item.relatedObjectId}</a></p>`
                : item.comment,
              type: 'success',
              timer: 10000,
            });
          }
        });
      });
    },
  },
};
</script>

<style lang="scss">
.notification__link {
  text-decoration: underline;
  color: var(--blue);

  cursor: pointer;
}
</style>
