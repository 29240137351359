export default {
  bind(el, binding, vnode) {
    el.handleOutsideClick = (e) => {
      const { handler, exclude } = binding.value;
      let clickedOnExcludedEl = false;

      exclude.forEach((refName) => {
        if (!clickedOnExcludedEl) {
          const excludedEl = vnode.context.$refs[refName];
          clickedOnExcludedEl = excludedEl.contains(e.target);
        }
      });

      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        vnode.context[handler]();
      }

      e.stopPropagation();
    };

    document.addEventListener('click', el.handleOutsideClick);
    document.addEventListener('touchstart', el.handleOutsideClick);
  },

  unbind(el) {
    if (el.handleOutsideClick) {
      document.removeEventListener('click', el.handleOutsideClick);
      document.removeEventListener('touchstart', el.handleOutsideClick);
    }
  },
};
