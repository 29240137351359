<template>
  <div class="modal-backdrop" v-show="show">
    <div class="modal">
      <button class="modal__button-close" @click="closeModal">
        <sm-icon name="times" />
      </button>
      <slot name="header">
        <h3 v-if="modalTitle" class="modal__title">{{ modalTitle }}</h3>
      </slot>
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
import SmIcon from '@/components/common/SmIcon.vue';
export default {
  name: 'SmModal',

  components: {
    SmIcon,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    modalTitle: {
      type: String,
      default: '',
    },
  },

  watch: {
    // Remove scroll from page
    show: {
      immediate: true,
      handler: (show) => {
        if (show) {
          document.body.style.setProperty('overflow', 'hidden');
        } else {
          document.body.style.removeProperty('overflow');
        }
      },
    },
  },

  created() {
    document.addEventListener('keydown', this.escapeHandler);
  },

  destroyed() {
    document.removeEventListener('keydown', this.escapeHandler);
  },

  methods: {
    closeModal() {
      this.$emit('close');
    },

    escapeHandler(event) {
      if (event.key === 'Escape' && this.show) {
        this.closeModal();
      }
    },
  },
};
</script>

<style lang="scss">
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: center;

  overflow: auto;

  background: rgba(var(--rgb-black), 0.5);
}

.modal {
  position: relative;

  max-width: 540px;
  padding: 40px 20px;
  margin: 32px auto;

  background: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(var(--rgb-black), 0.09);
}

.modal__button-close {
  position: absolute;
  top: 20px;
  right: 20px;

  font-size: 0;
  color: var(--gray);

  border: none;
  background: none;
  cursor: pointer;

  &:hover {
    color: var(--blue);
  }
}

.modal__title {
  margin-bottom: 20px;

  font-size: 20px;
  text-align: center;
}
</style>
