import { aeroApiMng } from '@/plugins/axios.js';

const getReportInfo = (params) => {
  return aeroApiMng
    .get('/Reports/ReportInfo', {
      params,
    })
    .then((response) => {
      let parameters = response.data.parameters.map((item) => {
        if (item.editor === 'Bool') {
          return { ...item, value: item.value === 'True' };
        }
        return item;
      });
      return { ...response.data, parameters };
    });
};

const generateReport = (payload) => {
  return aeroApiMng.post('/Reports/GenerateReport', payload);
};

const updateDependentList = (payload) => {
  return aeroApiMng.post('/Reports/DependentList', payload);
};

const downloadReport = (payload) => {
  return aeroApiMng.post('/Reports/GenerateReport', payload, {
    responseType: 'blob',
  });
};

function exportReport(data) {
  return aeroApiMng.post(`/Reports/ExportReport`, data, {
    responseType: 'blob',
  });
}

export default {
  getReportInfo,
  generateReport,
  updateDependentList,
  downloadReport,
  exportReport,
};
