import { aeroApiMng } from '@/plugins/axios.js';

const getFilterCatalogs = ({ catalogName, params }) => {
  return aeroApiMng
    .get(`/FilterCatalogs/${catalogName}`, {
      params,
    })
    .then(({ data }) => data);
};

const getFilterItemsById = (payload) => {
  return aeroApiMng.post(`/FilterCatalogs/ItemsById`, payload);
};

const getFilterCatalogsItems = (payload) => {
  return aeroApiMng
    .post(`/FilterCatalogs/Items`, payload)
    .then(({ data }) => data);
};

export default {
  getFilterCatalogs,
  getFilterItemsById,
  getFilterCatalogsItems,
};
