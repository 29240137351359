import telemarketingApi from '@/api/telemarketing.js';

const state = {
  organizationsRoles: {
    isLoading: false,
    data: [],
    errors: null,
  },

  organizationsRegions: {
    isLoading: false,
    data: [],
    errors: null,
  },
};

const mutations = {
  SET_LOADING: (state, { key, status }) => {
    state[key].isLoading = status;
  },

  SET_DATA: (state, { key, data }) => {
    state[key].data = data;
  },

  SET_ERROR: (state, { key, error }) => {
    state[key].error = error;
  },
};

const actions = {
  createManyTasks(context, payload) {
    return telemarketingApi
      .createManyTasks(payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  sendEmail(context, payload) {
    return telemarketingApi
      .sendEmail(payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  sendSms(context, payload) {
    return telemarketingApi
      .sendSms(payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  changePerformer(context, payload) {
    return telemarketingApi
      .changePerformer(payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  getOrganizationsRoles({ commit }, payload) {
    commit('SET_LOADING', { key: 'organizationsRoles', status: true });
    return telemarketingApi
      .getOrganizationsRoles(payload)
      .then((data) => {
        commit('SET_DATA', { key: 'organizationsRoles', data });
        return data;
      })
      .finally(() => {
        commit('SET_LOADING', { key: 'organizationsRoles', status: false });
      });
  },

  getOrganizationsRegions({ commit }, payload) {
    commit('SET_LOADING', { key: 'organizationsRegions', status: true });
    return telemarketingApi
      .getOrganizationsRegions(payload)
      .then((data) => {
        commit('SET_DATA', { key: 'organizationsRegions', data });
        return data;
      })
      .finally(() => {
        commit('SET_LOADING', { key: 'organizationsRegions', status: false });
      });
  },

  getTelemarketingScriptQuestions(_, payload) {
    return telemarketingApi
      .telemarketingScriptQuestions(payload)
      .then(({ data }) => {
        return data;
      });
  },
};

export default {
  name: 'telemarketing',
  namespaced: true,
  state,
  mutations,
  actions,
};
