import sitesApi from '@/api/sites.js';

const state = {
  sites: {
    isLoading: false,
    data: [],
    errors: null,
  },
};

const mutations = {
  SET_LOADING: (state, { key, status }) => {
    state[key].isLoading = status;
  },

  SET_DATA: (state, { key, data }) => {
    state[key].data = data;
  },

  SET_ERROR: (state, { key, error }) => {
    state[key].error = error;
  },
};

const actions = {
  fillSites(context, payload) {
    return sitesApi
      .fillSites(payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
};

export default {
  name: 'sites',
  namespaced: true,
  state,
  mutations,
  actions,
};
