import { aeroApiMng } from '@/plugins/axios.js';

const API_ENDPOINT = 'Sites';

function getSettings(dbName) {
  return aeroApiMng
    .get(`${API_ENDPOINT}/GetSiteSettings/${dbName}`)
    .then((res) => res.data);
}

function setSettings({ dbName, settings }) {
  return aeroApiMng.post(`${API_ENDPOINT}/SetSiteSettings/${dbName}`, {
    ...settings,
  });
}

export default {
  getSettings,
  setSettings,
};
