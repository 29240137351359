<template>
  <transition name="fade">
    <div v-if="!hidden" class="notification" :class="`notification--${type}`">
      <div class="notification__header">
        <div class="notification__header-column">{{ header }}</div>
        <div class="notification__header-column">
          <span
            @click="hide"
            class="notification__btn notification__btn--close"
          ></span>
        </div>
      </div>
      <p class="notification__text" v-html="text"></p>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'SmNotificationMessage',

  props: {
    header: {
      type: String,
    },

    text: {
      type: String,
    },

    type: {
      type: String,
      default: 'neutral',
    },
  },

  data() {
    return {
      hidden: false,
    };
  },

  methods: {
    hide() {
      this.hidden = true;
      this.$emit('hide')
    },
  },
};
</script>
