import Vue from 'vue';
import Vuex from 'vuex';

// modules
import account from '@/store/modules/account.js';
import common from '@/store/modules/common.js';
import dbMaintenance from '@/store/modules/dbMaintenance.js';
import editableList from '@/store/modules/editableList.js';
import pendingFiles from '@/store/modules/pendingFiles.js';
import visitedPages from '@/store/modules/visitedPages.js';
import reports from '@/store/modules/reports.js';
import telemarketing from '@/store/modules/telemarketing.js';
import amoCrm from '@/store/modules/amoCrm.js';
import requestQueryReport from '@/store/modules/requestQueryReport.js';
import sip from '@/store/modules/sip.js';
import reminders from '@/store/modules/reminders.js';
import oneCContracts from '@/store/modules/oneCContracts.js';
import krisha from '@/store/modules/krisha.js';
import sites from '@/store/modules/sites.js';
import deploy from '@/store/modules/deploy.js';
import customers from '@/store/modules/customers.js';
import siteSettings from '@/store/modules/siteSettings.js';
import filter from '@/store/modules/filter.js';
import meterRecheckRequests from './modules/meterRecheckRequests.js';
import technicalSupportHelpTree from './modules/technicalSupportHelpTree.js';
import email from '@/store/modules/email.js';
import debtors from '@/store/modules/debtors.js';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    account,
    common,
    dbMaintenance,
    editableList,
    pendingFiles,
    visitedPages,
    reports,
    telemarketing,
    amoCrm,
    requestQueryReport,
    sip,
    reminders,
    oneCContracts,
    krisha,
    deploy,
    customers,
    siteSettings,
    sites,
    filter,
    meterRecheckRequests,
    technicalSupportHelpTree,
    email,
    debtors,
  },
});
