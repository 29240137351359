import reportsApi from '@/api/reports.js';
import downloadHelpers from '@/utils/downloadHelpers.js';

const state = {
  initialValue: {
    isLoading: false,
    data: null,
    errors: null,
  },

  reportInfo: {
    isLoading: false,
    data: null,
    errors: null,
  },

  report: {
    isLoading: false,
    data: null,
    errors: null,
  },

  loadingSatusReport: false,
};

const mutations = {
  SET_LOADING: (state, { key, status }) => {
    state[key].isLoading = status;
  },

  SET_DATA: (state, { key, data }) => {
    state[key].data = data;
  },

  SET_ERROR: (state, { key, error }) => {
    state[key].error = error;
  },

  RESET_STORE: (state) => {
    state.reportInfo = { ...state.initialValue };
    state.report = { ...state.initialValue };
  },

  SET_LOADING_STATUS_REPORT: (state, status) => {
    state.loadingSatusReport = status;
  },
};

const actions = {
  getReportInfo({ commit }, params) {
    commit('SET_LOADING', { key: 'reportInfo', status: true });
    commit('SET_LOADING_STATUS_REPORT', true);

    return reportsApi
      .getReportInfo(params)
      .then((response) => {
        commit('SET_DATA', { key: 'reportInfo', data: response });
        return response;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        commit('SET_LOADING', { key: 'reportInfo', status: false });
        commit('SET_LOADING_STATUS_REPORT', false);
      });
  },

  generateReport({ commit }, payload) {
    commit('SET_LOADING', { key: 'report', status: true });
    commit('SET_LOADING_STATUS_REPORT', true);

    return reportsApi
      .generateReport(payload)
      .then((response) => {
        commit('SET_DATA', { key: 'report', data: response.data });
        return response.data;
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit('SET_LOADING', { key: 'report', status: false });
        commit('SET_LOADING_STATUS_REPORT', false);
      });
  },

  updateDependentList(context, params) {
    return reportsApi
      .updateDependentList(params)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw new Error(error);
      });
  },

  downloadReport(context, payload) {
    return reportsApi
      .downloadReport(payload)
      .then((response) => {
        downloadHelpers(response.data, response.headers['content-disposition']);
        return response.data;
      })
      .catch((error) => {
        throw new Error(error);
      });
  },

  resetStore({ commit }) {
    commit('RESET_STORE');
  },

  exportReport(context, data) {
    return reportsApi
      .exportReport(data)
      .then((res) => {
        downloadHelpers(res.data, res.headers['content-disposition']);
      })
      .catch((err) => console.error(err));
  },
};

export default {
  name: 'reports',
  namespaced: true,
  state,
  mutations,
  actions,
};
