import { aeroApiMng } from '@/plugins/axios.js';

const createManyTasks = (payload) => {
  return aeroApiMng.post(`/TelemarketingTasks/CreateMany`, payload);
};

const sendEmail = (payload) => {
  return aeroApiMng.post(`/TelemarketingTasks/SendEmail`, payload);
};

const changePerformer = (payload) => {
  return aeroApiMng.post(`/TelemarketingTasks/ChangePerformer`, payload);
};

const sendSms = (payload) => {
  return aeroApiMng.post(`/Misc/SendSms`, payload);
};

const getOrganizationsRoles = () => {
  return aeroApiMng.get(`/Common/OrganizationsRoles`).then((response) => {
    return response.data.map((option) => ({
      name: option,
      value: option,
    }));
  });
};

const getOrganizationsRegions = () => {
  return aeroApiMng.get(`/Common/OrganizationsRegions`).then((response) => {
    return response.data.map((option) => ({
      name: option.regionName,
      value: option.regionCode,
    }));
  });
};

const telemarketingScriptQuestions = (scriptId) => {
  return aeroApiMng.get(`TelemarketingScripts/Questions`, {
    params: { scriptId },
  });
};

export default {
  createManyTasks,
  sendEmail,
  sendSms,
  getOrganizationsRoles,
  getOrganizationsRegions,
  changePerformer,
  telemarketingScriptQuestions,
};
