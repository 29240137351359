const permissions = {};

const setPermissions = (value) => {
  permissions.hasFullAccess = value.hasFullAccess;
  permissions.list = [...value.list];
};

const checkPermissions = (type, controllerName, action) => {
  if (permissions.hasFullAccess) return true;
  if (type === 'Table') {
    return permissions.list.includes(
      `${type}.${controllerName}Controller.${action}`
    );
  }
  if (type === 'Report') {
    return permissions.list.includes(`${type}.${controllerName}.${action}`);
  }
};

module.exports = {
  install(Vue) {
    Object.defineProperties(Vue.prototype, {
      $ability: {
        get() {
          return {
            setPermissions,
            checkPermissions,
            permissions,
          };
        },
      },
    });
  },
  setPermissions,
  checkPermissions,
};
