import meterRecheckRequestsApi from '@/api/meterRecheckRequests.js';
import downloadHelpers from '@/utils/downloadHelpers.js';

const state = {
  data: null,
  filters: null,
  resources: [],
}

const mutations = {
  SET_DATA: (state, { key, data }) => {
    state[key] = data;
  },

  SET_FILTERS: (state, { key, filters }) => {
    state[key] = filters;
  },
}

const actions = {
  meterRecheckRequests({ commit }, payload) {
    commit('SET_FILTERS', { key: 'filters', filters: payload });
    return meterRecheckRequestsApi
      .meterRecheckRequests(payload)
      .then((response) => {
        commit('SET_DATA', { key: 'data', data: response.data });
        return response.data;
      })
      .catch((error) => {
        throw new Error(error);
      });
  },

  downloadAct(context, payload) {
    meterRecheckRequestsApi
      .downloadAct(payload)
      .then((response) => {
        downloadHelpers(response.data, response.headers['content-disposition']);
      })
      .catch((error) => {
        throw new Error(error);
      });
  },

  meterInfo(context, payload) {
    return meterRecheckRequestsApi
      .metersInfo(payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw new Error(error);
      });
  },

  resources({ commit }, payload) {
    return meterRecheckRequestsApi
      .resources(payload)
      .then((response) => {
        const data = response.data.map((option) => ({
          ...option,
          value: option.id,
        }));

        commit('SET_DATA', { key: 'resources', data: data });

        return data;
      })
      .catch((error) => {
        throw new Error(error);
      });
  },

  updateMetersInfo(context, payload) {
    return meterRecheckRequestsApi
      .updateMetersInfo(payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
}

export default {
  name: 'meterRecheckRequests',
  namespaced: true,
  state,
  mutations,
  actions,
};