import sipApi from '@/api/sip.js';

const state = {};

const mutations = {};

const actions = {
  generateSipKey() {
    return sipApi
      .generateSipKey()
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        throw error;
      });
  },
};

export default {
  name: 'sip',
  namespaced: true,
  state,
  mutations,
  actions,
};
