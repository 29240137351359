import { aeroApiMng } from '@/plugins/axios.js';

/**
 * Получение списка проектов
 *
 * @return  {Array}  Список проектов
 */
const getProjects = () => {
  return aeroApiMng.get('Deploy/Projects');
};

/**
 * Получение информации по проекту
 *
 * @param   {number}  projectId  id проекта
 *
 * @return  {Object}             Информация по проекту
 */
const getProjectInfo = ({ projectId }) => {
  return aeroApiMng.get(`Deploy/ProjectInfo/${projectId}`);
};

/**
 * Получение информации по релизу
 *
 * @param   {number}  releaseId  id релиза
 *
 * @return  {Object}             Информация по релизу
 */
const getReleaseInfo = ({ releaseId }) => {
  return aeroApiMng.get(`Deploy/ReleaseInfo/${releaseId}`);
};

/**
 * Получение информации о публикации релиза
 *
 * @param   {Object}  params
 * @param   {number}  params.publicationId  id публикации
 * @param   {number}  params.releaseId      id релиза
 *
 * @return  {Object}                        Информация о публикации релиза
 */
const getReleasePublicationInfo = (params) => {
  return aeroApiMng.get('Deploy/ReleasePublicationInfo', { params });
};

/**
 * Получение содержимого файла лога
 *
 * @param   {number}  logId  id файла лога
 *
 * @return  {Object}         Содержимого файла лога
 */
const getLog = ({ logId }) => {
  return aeroApiMng.get(`Deploy/Log/${logId}`);
};

/**
 * Добавление нового файла релиза
 *
 * @param   {Object}  payload
 * @param   {number}  payload.projectId      id проекта
 * @param   {Object}  payload.file           файл релиза (zip архив)
 *
 * @return  {Object}                        Информация о добавлении релиза
 */
const addRelease = (payload) => {
  return aeroApiMng.post('Deploy/AddRelease', payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export default {
  getProjects,
  getProjectInfo,
  getReleaseInfo,
  getReleasePublicationInfo,
  getLog,
  addRelease,
};
