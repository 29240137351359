import { aeroApiMng } from '@/plugins/axios.js';

const createDatabase = (payload) => {
  return aeroApiMng.post(`/DbMaintenance/CreateDb`, payload);
};

const createDemoDatabase = (payload) => {
  return aeroApiMng.post(`/DbMaintenance/CreateDemoDb`, payload);
}

const truncateDatabase = (payload) => {
  return aeroApiMng.post(`/DbMaintenance/TruncateDb`, payload);
};

export default {
  createDatabase,
  truncateDatabase,
  createDemoDatabase
};
