import { apiExch } from '@/plugins/axios.js';

const addCompanyToAmo = (payload) => {
  return apiExch.post('/amocrm.ashx?action=add_company', payload, {
    headers: {
      Authorization: 'Basic MWNfdXNlcjpqaEs4eEQ=',
      'Content-Type': 'multipart/form-data',
    },
  });
};

export default { addCompanyToAmo };
